import { FieldValues, UseFormSetValue } from "react-hook-form";
import { Quote } from "../types/Quote.interface";
import { Species } from "spot-types/entities/Species";
import { QuoteAPI } from "./QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { AnalyticsUtils } from "./AnalyticsUtils";
import { PublicConfig } from "../PublicConfig";
import { QueryClient } from "@tanstack/react-query";
import { fetchOneEntry, getBuilderSearchParams, GetContentOptions } from "@builder.io/sdk-react";
import { CustomizationSlotProps } from "../components/CustomizationSlot";

export type BuilderDataType = keyof (typeof BuilderUtils)["typeToFieldKeyMap"];

export type BuilderTargetingAttributes = {
    cobrandId?: string;
    formId?: string;
    formStepId?: string;
    partnerCodeLowerCase?: string;
    quoteHasCats?: boolean;
    quoteHasDogs?: boolean;
    quoteHasIllnessCoverage?: boolean;
    quoteLocation?: string;
    quotePetCount?: string;
    quoteUnderwriter?: PetUnderwriterType;
    queryParamsStringLowerCase?: string;
    statsigMainLayerId?: string;
    statsigMainRuleId?: string;
    statsigMainAllocatedExperimentName?: string;
    statsigDogLayerId?: string;
    statsigDogRuleId?: string;
    statsigDogAllocatedExperimentName?: string;
    statsigCatLayerId?: string;
    statsigCatRuleId?: string;
    statsigCatAllocatedExperimentName?: string;
    builderTestIds?: string;
    builderVariationIds?: string;
};

export interface PrefetchBuilderContentArgs {
    queryClient: QueryClient;
    type: CustomizationSlotProps["type"];
    underwriterValue: PetUnderwriterType;
    quoteData?: Quote;
    normalizedParams?: Record<string, string>;
    priorityCodeValue?: string;
}

export class BuilderUtils {
    quoteApi: QuoteAPI;

    constructor(underwriter: PetUnderwriterType) {
        this.quoteApi = new QuoteAPI(underwriter);
    }

    static getBuilderAttributes = ({
        data,
        formId,
        formStepId,
        priorityCode,
        builderTestIds,
        builderVariationIds
    }: {
        data?: Quote;
        formId?: string;
        formStepId?: string;
        priorityCode?: string;
        builderTestIds?: string[];
        builderVariationIds?: string[];
    }): BuilderTargetingAttributes => {
        const attributes: BuilderTargetingAttributes = {
            formId: formId,
            formStepId: formStepId,
            partnerCodeLowerCase: priorityCode ?? data?.discountCode?.toLowerCase() ?? data?.affiliateCode?.toLowerCase() ?? undefined,
            quoteHasCats: data?.policies?.some(policy => policy.species === Species.Cat) ?? false,
            quoteHasDogs: data?.policies?.some(policy => policy.species === Species.Dog) ?? false,
            quoteHasIllnessCoverage: data?.policies?.some(policy => policy.coverageSettings?.coverages?.some(coverage => coverage.type === "illness")),
            quoteLocation: data?.ratingAddress?.state,
            quotePetCount: (data?.policies?.length ?? 0).toString(),
            quoteUnderwriter: data?.underwriter,
            queryParamsStringLowerCase: "",
            builderTestIds: JSON.stringify(builderTestIds ?? []),
            builderVariationIds: JSON.stringify(builderVariationIds ?? [])
        };

        const statsigData = AnalyticsUtils.getStatsigExposureData();

        if (statsigData) {
            const mainLayerData = statsigData.statsig[PublicConfig.STATSIG_LAYER_ID_MAIN];
            const dogLayerData = statsigData.statsig[PublicConfig.STATSIG_LAYER_DOG_EXPERIMENTS];
            const catLayerData = statsigData.statsig[PublicConfig.STATSIG_LAYER_CAT_EXPERIMENTS];

            if (mainLayerData && typeof mainLayerData !== "string") {
                attributes.statsigMainLayerId = PublicConfig.STATSIG_LAYER_ID_MAIN;
                attributes.statsigMainRuleId = mainLayerData.ruleID.split(":")[0];
                attributes.statsigMainAllocatedExperimentName = mainLayerData.allocatedExperimentName;
            }

            if (dogLayerData && typeof dogLayerData !== "string") {
                attributes.statsigDogLayerId = PublicConfig.STATSIG_LAYER_DOG_EXPERIMENTS;
                attributes.statsigDogRuleId = dogLayerData.ruleID.split(":")[0];
                attributes.statsigDogAllocatedExperimentName = dogLayerData.allocatedExperimentName;
            }

            if (catLayerData && typeof catLayerData !== "string") {
                attributes.statsigCatLayerId = PublicConfig.STATSIG_LAYER_CAT_EXPERIMENTS;
                attributes.statsigCatRuleId = catLayerData.ruleID.split(":")[0];
                attributes.statsigCatAllocatedExperimentName = catLayerData.allocatedExperimentName;
            }
        }

        return attributes;
    };

    private static typeToFieldKeyMap = {
        modal: "modalStates",
        form: "formData"
        // Additional types can be added here
    };

    async updateQuoteExtraData({
        quote,
        newDataArray,
        updateQuote
    }: {
        quote?: Quote;
        newDataArray: { type: BuilderDataType; data: FieldValues }[];
        updateQuote?: UseFormSetValue<Quote>;
    }) {
        if (!quote) return;
        const quoteId = quote?.id;
        const currentExtraData = quote?.extra ?? {};
        const updatedExtraData = { ...currentExtraData };

        for (const newData of newDataArray) {
            const fieldKey = BuilderUtils.typeToFieldKeyMap[newData.type];
            const currentFieldData = updatedExtraData[fieldKey] ?? {};
            updatedExtraData[fieldKey] = { ...currentFieldData, ...newData.data };
        }

        const quoteStatus = quote?.quoteStatus;

        if (quoteStatus === "finalized" && !!quoteId) {
            await this.quoteApi.setScratchPadValue(quoteId, "extra", updatedExtraData);
        } else if (updateQuote) {
            updateQuote("extra", updatedExtraData);
        }
    }

    static async prefetchBuilderContent({ queryClient, type, underwriterValue, quoteData, normalizedParams, priorityCodeValue }: PrefetchBuilderContentArgs) {
        const builderAttributes: BuilderTargetingAttributes = {
            formId: PublicConfig.PTZ_US.FORM_ID,
            partnerCodeLowerCase: priorityCodeValue?.toLowerCase(),
            quoteHasCats: quoteData?.policies?.some(policy => policy.species === "Cat") ?? false,
            quoteHasDogs: quoteData?.policies?.some(policy => policy.species === "Dog") ?? false,
            quoteHasIllnessCoverage: quoteData?.policies?.some(policy => policy.coverageSettings?.coverages?.some(coverage => coverage.type === "illness")),
            quoteLocation: quoteData?.ratingAddress?.state,
            quotePetCount: (quoteData?.policies?.length ?? 0).toString(),
            quoteUnderwriter: underwriterValue,
            queryParamsStringLowerCase: normalizedParams?.toString().toLowerCase()
        };

        const entryOptions: GetContentOptions = {
            options: getBuilderSearchParams(normalizedParams),
            apiKey: PublicConfig.BUILDER_IO_PUBLIC_API_KEY,
            model: type,
            userAttributes: builderAttributes
        };

        if (PublicConfig.ENVIRONMENT !== "production") {
            entryOptions.cacheSeconds = 0;
            entryOptions.staleCacheSeconds = 0;
            entryOptions.options = {
                cachebust: true
            };
        }

        const getBuilderContent = async () => {
            try {
                return await fetchOneEntry(entryOptions);
            } catch (error) {
                console.log(`Error fetching builder content: ${error}`);
                return null;
            }
        };

        try {
            return await queryClient.fetchQuery({
                queryKey: ["builder-ssr-content", type],
                queryFn: getBuilderContent
            });
        } catch (error) {
            console.log(`Error fetching builder content: ${error}`);
            return null;
        }
    }
}
