// Shared strings used across forms
export default class Strings {
    static SELECT_AGE = "Select Age *";
    static AGE = "Age *";
    static YOUR_PETS_NAME = "Your Pet's Name *";
    static PETS_NAME = "Pet's Name *";
    static NAME = "Name *";
    static NO_PET_NAME = "I don’t have my pet yet";
    static TEMP_PET_NAME = "Your Pet";
    static TELL_US_PET = "Tell Us About Your Pet";
    static ADD_PET = "Add a Pet";
    static FIRST_NAME = "First Name *";
    static LAST_NAME = "Last Name *";
    static CAT_BREED_ORDER = [
        "Domestic Shorthair",
        "Domestic Shorthair Mix",
        "Domestic Mediumhair",
        "Domestic Medium Hair",
        "Domestic Medium Hair Mix",
        "Domestic Longhair",
        "Domestic Longhair Mix",
        "Mixed Cat"
    ];
    static ADD_ADDITIONAL_PET = "Add Another Pet";
    static ADD_PET_GET_DISCOUNT = "Add a Pet & Get 10% Off";
    static MULTIPET_DISCOUNT_LABEL = "10% multi-pet discount on all additional pets";
    static MULTIPET_DISCOUNT_ADDITIONAL_PETS = "10% off all additional pets";
    static DISCOUNTS_APPLIED = "Discount(s) Applied";
    static ENTER_PET_INFO = "Enter Pet Info";
    static MORE_PETS = "Got more pets? Add them on the next screen.";
    static SELECT_MONTH = " Birth Month *";
    static SELECT_YEAR = " Birth Year *";
    static SELECT_BREED = "Select Breed *";
    static BREED = "Breed *";
    static SEARCH_BREEDS = "Search Breeds";
    static YOUR_FIRST_NAME = "Your First Name *";
    static YOUR_LAST_NAME = "Your Last Name *";
    static EMAIL_ADDRESS = "Email Address *";
    static ZIP_CODE = "Zip Code *";
    static CALCULATING = "Calculating...";
    static ANNUAL_BILLING_LABEL = "Annual billing";
    static MULTIPET_LINKED_LABEL = "Apply to all pets";
    static MULTIPET_TOGGLE_HINT = "Click or tap names to toggle between pets";
    static NOT_APPLICABLE = "N/A";
    static THANKS_JOINING = "Thanks for joining the pack!";
    static PROBLEM_FETCH_QUOTE = `We’re having trouble fetching your quote.`;
    static CLOSE_NOTIFICATION = "Close notification";
    static CREATE_PLAN = "Create your plan";
    static PROCEED_TO_CHECKOUT = "Proceed to Checkout";
    static MOBILE_PHONE = "Mobile Number";
    static MOBILE_PHONE_OPTIONAL = "Mobile Number (optional)";
    static UNLIMITED_ANNUAL_LIMIT_LABEL = "Unlimited";
    static EDIT_PETS = "Edit Pets";
    static EDIT_PET = "Edit Pet";
    static EDIT_PET_DETAILS = "Edit Pet Details";
    static REMOVE_PET = "Remove Pet";
    static REMOVE_PET_CONFIRMATION = "Are you sure you want to remove";
    static CONFIRMATION_CANCEL = "No, cancel";
    static COMPLETE_CHECKOUT = "Complete Checkout";
    static COVERAGE_DETAILS = "Coverage Details";
    static TERMS_MEANING = "What do these terms mean?";
    static WRAPPING_UP = "Wrapping up...";
    static START_OVER = "Start Over";
    static ADD_MULTIPLE_PETS_LATER = "You can add multiple pets later";
    static ADD_EDIT_PETS_LATER = "You can add/edit pets later";
    static SAVE = "Save";
    static POLICY_NEXT_STEP_CTA = "Check Pricing";
    static ERRORS = {
        PET_NAME: "Pet name is required",
        PET_NAME_CHARS: "Pet name can only contain letters and numbers",
        PET_NAME_LENGTH: "Pet name cannot be more than 50 characters long",
        PET_NAME_INVALID: "Pet name cannot match owner's name",
        PET_AGE: "Pet age is required",
        PET_AGE_INVALID: "Pet age is invalid",
        PET_BREED: "Pet breed is required",
        PHONE: "Enter a valid phone number",
        FIRST_NAME: {
            REQUIRED: "First name is required",
            MIN: "First name must be at least 2 characters",
            MAX: "First name must be 50 characters or less"
        },
        LAST_NAME: {
            REQUIRED: "Last name is required",
            MIN: "Last name must be at least 2 characters",
            MAX: "Last name must be 50 characters or less"
        },
        EMAIL: {
            MIN: "Email address is required",
            MAX: "Email must be 50 characters or less"
        },
        EMAIL_INVALID: "Enter a valid email address",
        ZIP: "Zip code is required",
        ZIP_INVALID: "Enter a valid zip code",
        CITY: "City is required",
        CITY_MAX: "City must be 50 characters or less",
        CITY_CHARS: "City must contain characters",
        FULL_NAME: {
            REQUIRED: "First and last names are required",
            MAX: "Full name must be 50 characters or less"
        },
        FIRST_LAST_NAMES: "First and last names are required",
        ADDRESS: "Address is required",
        ADDRESS_MAX: "Address must be 50 characters or less",
        ADDRESS_CHARS: "Address must contain characters",
        STATE: "State is required",
        POSTAL_CODE: "Postal code is required",
        POSTAL_CODE_INVALID: "Enter a valid postal code",
        CARD_CVV: "Invalid Security Code",
        CARD_EXPIRY: "Invalid exp date",
        DECLINE_GENERIC: "We couldn't process your payment",
        BIRTH_MONTH: "Birth month is required",
        BIRTH_YEAR: "Birth year is required",
        PET_MIN_AGE: "Your pet needs to be at least 8 weeks old",
        REQUIRED: "Required",
        LOCATION_UNAVAILABLE: "We're sorry, Spot is not available in your area",
        MICROCHIP: "Microchip number must be between 9 - 15 digits",
        MICROCHIP_INVALID_ASYNC: "Invalid microchip number",
        CONTACT_CONSENT: "Please agree to contact consent",
        LENGTH_MAX: "Too Long!"
    };

    static PTZ_US = {
        META_TITLE: "Free Pet Insurance Quote | Pick Your Coverage",
        META_DESCRIPTION: "Spot is proud to offer pet parents a free pet insurance quote. Visit us to learn more about our different coverage options and what is best for you.",
        COPYRIGHT_TEXT: "%year% United States Fire Insurance Company. Spot Pet Insurance Logo, Copyright %year%, Spot Pet Insurance. All Rights Reserved.",
        SELECT_COVERAGE_TEXT: "Select Coverage",
        BILLING_NAME: "Full Name",
        ADDRESS_LINE_1: "Address Line 1",
        ADDRESS_LINE_2: "Address Line 2",
        STATE: "State",
        PHONE_NUMBER: "18009051595",
        HOURS: "Mon – Fri | 8am – 8pm",
        // DISPLAY_NUMBER: "888-880-SPOT",
        PLAN_PDF_LINK_TEXT: "View Plan Details",
        GIFTCARD: {
            REWARD: "$25 Amazon Gift Card",
            UNAVAILABLE: "Amazon Gift Card* Unavailable in Your State",
            APPLIED: "$25 Amazon Gift Card Applied!*",
            APPLY: "Apply and get a",
            SIGN_UP: "Sign up and get a",
            QUALIFY: "See if you qualify for a"
        },
        PREVENTIVE_BASIC: "Gold",
        PREVENTIVE_ADVANCED: "Platinum",

        BENEFITS_PREVENTIVE: {
            SPAY_OR_DENTAL: "Spay/Neuter or Dental Cleaning",
            DENTAL_OR_SPAY: "Dental Cleaning / Spay/Neuter"
        },

        PUPPY_KITTEN_AGE: "8 weeks to 11 months"
    };
    static PTZ_CA = {
        META_TITLE: "Free Pet Insurance Quote | Spot Pet Insurance (Canada)",
        META_DESCRIPTION: "Get your free pet insurance quote. Spot Pet Insurance offers personalized pet plan options for dogs and cats.",
        COPYRIGHT_TEXT: "%year% All Rights Reserved. Spot Pet Insurance Logo, Copyright %year%, Spot Pet Insurance.",
        SELECT_COVERAGE_TEXT: "Select Coverage",
        PHONE_NUMBER: "18885017768",
        HOURS: `Mon – Fri | 8am – 8pm EST \n Sat | 9am – 5pm EST`,
        POSTAL_CODE: "Postal Code *",
        POSTAL_CODE_PLACEHOLDER: "V5R 4B3",
        MULTIPET_DISCOUNT_LABEL: "Up to 10% multi-pet discount on all pets",
        // DISPLAY_NUMBER: "888-501-SPOT",
        PLAN_PDF_LINK_TEXT: "View Plan Details",
        PREVENTIVE_BASIC: "Routine",
        PREVENTIVE_ADVANCED: "Advanced"
    };
    static DATA_DOG = {
        POLICY_STEP_CTA: "Policy Step CTA",
        COVERAGE_STEP_CTA: "Coverage Step CTA",
        BILLING_STEP_CTA: "Billing Step CTA"
    };
}
